import * as React from 'react'

import * as plume from '@ulule/owl-kit-components/next'

import { type Action, type State, messagesReducer } from './reducer'

export type LevelCallout = 'alert' | 'error' | 'info' | 'notification' | 'success'
export type LevelToast = 'alert' | 'error' | 'info' | 'success'
export type LevelFlash = LevelCallout

export type MessageBase = {
  context: string
  deleted?: boolean
  forceClose?: boolean
  message: React.ReactNode
  onClose?: () => void
  timeOpen?: number
  zIndexLevel?: 'high' | 'low'
} & (MessageLinkProps | MessageButtonProps)

type MessageLinkProps = {
  link?: plume.LinkProps
  button?: undefined
}

type MessageButtonProps = {
  link?: undefined
  button?: plume.LinkAsButtonProps
}

export type MessageCallout = MessageBase & {
  level: LevelCallout
  type: 'callout'
}

export type MessageToast = MessageBase & {
  level: LevelToast
  type: 'toast'
}

export type MessageFlash = MessageBase & {
  message: React.ReactNode
  level: LevelFlash
  type: 'flash'
}

export type Message = MessageFlash | MessageToast | MessageCallout

type Value = {
  state: State
  dispatch: React.Dispatch<Action>
}

const messagesContext = React.createContext<Value | undefined>(undefined)

function MessagesProvider({ children }: { children: React.ReactNode }): React.ReactElement {
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(messagesReducer, {})
  const value = { state, dispatch }
  return <messagesContext.Provider value={value}>{children}</messagesContext.Provider>
}

function useMessagesContext() {
  const context = React.useContext(messagesContext)
  if (context === undefined) {
    throw new Error('useMessagesContext must be used within a messagesProvider')
  }
  return context
}

export { MessagesProvider, useMessagesContext }
